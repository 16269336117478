import { LOCAL_STORAGE_KEYS } from '../Constantes';

export const ROLE = {
    ADMIN: { value: 'ROLE_ADMIN', step: 0, color: '', label: 'Administrateur' },
    RAYONNISTE: { value: 'ROLE_RAYONNISTE', step: 0, color: '', label: 'Rayonniste' },
    GERANT: { value: 'ROLE_GERANT', step: 1, color: '', label: 'Gérant' },
    USER: { value: 'ROLE_USER', step: 1, color: '', label: 'Utilisateur' },
    FACTUREUR: { value: 'ROLE_FACTURATION', step: 2, color: '', label: 'Factureur' },
    GERANT_DEPOT: { value: 'ROLE_GERANT_DEPOT', step: 2, color: '', label: 'Gérant du dépôt' },
    VOIR_MONTANT: { value: 'ROLE_VOIR_MONTANT', step: 0, color: '', label: 'Utilisateur' },
    EXPORT: { value: 'ROLE_EXPORTATION', step: 0, color: '', label: 'Utilisateur' },
    CONTROLEUR: { value: 'ROLE_CONTROLEUR', step: 4, color: '', label: 'Contrôleur du dépôt' },
    CHARGEUR: { value: 'ROLE_CHARGEUR', step: 3, color: '', label: 'Chargeur' },
    LIVREUR: { value: 'ROLE_LIVREUR', step: 2, color: '', label: 'Livreur' },
    CONTROLEUR_DU_MAGASIN: { value: 'ROLE_CONTROLEUR_DU_MAGASIN', step: 4, color: '', label: 'Contrôleur du magasin' },
    GESTIONNAIRE: { value: 'ROLE_GESTIONNAIRE', step: 3, color: '', label: 'Gestionnaire' },
}

const getRolesOfCurrentUser = () => {
    let currentUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER))
    return currentUser?.roles ? currentUser.roles : []
}

export const isAdmin = () => {
    return getRolesOfCurrentUser().includes(ROLE.ADMIN.value)
}

export const isGerant = () => {
    return getRolesOfCurrentUser().includes(ROLE.GERANT.value)
}

export const isUser = () => {
    return getRolesOfCurrentUser().includes(ROLE.USER.value)
}

export const isRayonniste = () => {
    return getRolesOfCurrentUser().includes(ROLE.RAYONNISTE.value)
}

export const isFactureur = () => {
    return getRolesOfCurrentUser().includes(ROLE.FACTUREUR.value)
}

export const isGerantDepot = () => {
    return getRolesOfCurrentUser().includes(ROLE.GERANT_DEPOT.value)
}

export const isGestionnaire = ()=>{
    return getRolesOfCurrentUser().includes(ROLE.GESTIONNAIRE.value)
}

export const LIST_APPS = [
    {
       code: "RS", 
       name: "Rapport de solde", 
       link: "https://store.bonprix.ci/", 
       color: "#004dcf", 
       primeicon: "pi pi-chart-line",
       description: "Application des rapports journaliers",
       visible: isAdmin() || isGerant() || isUser()
    },
    {
       code: "CCP", 
       name: "Commande Cash Port", 
       link: "https://sp.bonprix.ci/", 
       color: "#fccb00", 
       primeicon: "pi pi-shopping-cart",
       description: "Application de passation des commandes au Cash Port",
       visible: isAdmin() || isFactureur() || isGerant() || isRayonniste() || isGestionnaire()
    },
    {
       code: "SF", 
       name: "Suivi des factures", 
       link: "http://suivifacture.pcp.com/", 
       color: "#bccbcb", 
       primeicon: "pi pi-folder-open",
       description: "Application de suivi des factures",
       visible: isAdmin() || isFactureur() || isGerantDepot()
    },
    {
       code: "LD", 
       name: "Livraison directe", 
       link: "https://ld.bonprix.ci/", 
       color: "#365276", 
       primeicon: "pi pi-shopping-cart",
       description: "Application de passation des commandes chez les fournisseurs directs",
       visible: isAdmin() || isFactureur() || isGerant() || isRayonniste()
    },
 ]