export const KEYCLOAK_URL = 'https://secure.bonprix.ci'
//export const KEYCLOAK_URL = 'http://185.209.223.110:9090'

export const APP_BASE_URL = '/app_manager'

export const APP_NAME = "APP MANAGER";

export const LOCAL_STORAGE_KEYS = {
   ACCESS_TOKEN: 'accessToken',
   CURRENT_USER: 'currentUser',
}

export const APP_URL = {
   HOME: { value: '/', description: "Accueil" },
   PROFILE: { value: '/profile', description: "Profile" }
}